









import { Component, Vue, Prop } from 'vue-property-decorator';
import SectionEditorToolbar from '@/components/editor/SectionEditor/SectionEditorToolbar.vue';

@Component({ components: { SectionEditorToolbar } })
export default class StagingSectionEditorHeader extends Vue {
  @Prop(String) public readonly sectionName!: string;

  @Prop({ type: Boolean, default: false })
  public readonly invisible!: boolean;
}
