































































import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Citation } from '@/store/modules/documents/types/citations.types';
import {
  PendingDocumentCitationReference,
  PendingDocumentReferences,
  PendingDocumentReferenceUpdate
} from '@/jbi-shared/types/document.types';
import { getCitationReferenceNumber } from '@/utils/citation-reference.util';
import StagingTextSectionCitationDialog from './StagingTextSectionCitationDialog.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { CitationTypes } from '@/jbi-shared/types/citation.fields';
import Cite from 'citation-js';
import { getDatePartsFromIssueDate } from '../../../../jbi-shared/util/citation.util';

@Component({})
export default class StagingTextSectionCitation extends mixins(ViewModeMixin) {
  @Prop()
  public currentCitation!: PendingDocumentCitationReference;
  @Prop()
  public dirtyCitations!: Citation[];
  @Prop()
  public previousCitations!: Citation[];
  @Prop()
  public dirtyReferences!: PendingDocumentReferences;

  get allCitations(): Citation[] {
    return this.dirtyCitations.concat(this.previousCitations);
  }

  get newlyAddedCitations() {
    const previousCitationIds = this.previousCitations.map(
      (citation) => citation.id
    );
    return this.dirtyCitations.filter((dirtyCitation) => {
      return !previousCitationIds.includes(Number(dirtyCitation.id));
    });
  }

  public selectedCitation: string = '';

  get proxySelectedCitation() {
    if (this.selectedCitation === '') {
      this.selectedCitation = this.currentCitation.citationId;
    }
    return this.selectedCitation;
  }
  set proxySelectedCitation(value) {
    this.selectedCitation = value;
  }

  @isDifferent
  @isTruthy
  @Watch('proxySelectedCitation')
  public onSelectedChange(value: string) {
    this.proxySelectedCitation = value;
  }

  public citationReferenceNumber(citation: Citation): number | undefined {
    return getCitationReferenceNumber(
      citation,
      this.dirtyReferences.citationIds || []
    );
  }

  public citationText(citation: Citation): string {
    if (citation.content.type === CitationTypes.PLAIN) {
      return citation.content.plainText;
    } else {
      const rawCitationObject = new Cite(citation.content);

      // Special Fix for Vancouver format to display date, and support multiple types of formats
      rawCitationObject.data[0].issued = getDatePartsFromIssueDate(
        rawCitationObject?.data[0]?.issued
      );

      const formattedCitation = rawCitationObject.format('bibliography', {
        template: 'vancouver'
      });
      return formattedCitation.trim().substr(3);
    }
  }

  public saveCitation() {
    if (this.proxySelectedCitation === this.currentCitation.citationId) {
      this.$emit('close');
    } else {
      const newCitation = this.allCitations.find(
        (citation) => String(citation.id) === String(this.proxySelectedCitation)
      );
      if (newCitation) {
        const referenceNumber = getCitationReferenceNumber(
          newCitation!,
          this.dirtyReferences.citationIds || []
        );
        if (!referenceNumber) {
          // New citation selected is not in used
          const payload: PendingDocumentReferenceUpdate = {
            mode: 'replace',
            label: this.currentCitation.label,
            previousCitationId: this.currentCitation.citationId,
            newCitationId: this.proxySelectedCitation
          };
          this.updateReferenceCitation(payload);
        } else {
          // New citation selected is in used, prompt dialog to replace/merge citation
          this.$buefy.modal.open({
            parent: this,
            component: StagingTextSectionCitationDialog,
            hasModalCard: true,
            trapFocus: true,
            props: {
              referenceNumber: getCitationReferenceNumber(
                newCitation!,
                this.dirtyReferences.citationIds || []
              )
            },
            events: {
              'update:referenceCitation': (
                mode: PendingDocumentReferenceUpdate['mode']
              ) => {
                const payload: PendingDocumentReferenceUpdate = {
                  mode,
                  label: this.currentCitation.label,
                  previousCitationId: this.currentCitation.citationId,
                  newCitationId: this.proxySelectedCitation
                };
                this.updateReferenceCitation(payload);
              }
            }
          });
        }
      }
    }
  }

  public updateReferenceCitation(
    referenceUpdatePayload: PendingDocumentReferenceUpdate
  ) {
    this.$emit('updateReferenceCitation', referenceUpdatePayload);
    this.$emit('close');
  }
}
