






























import { Vue, Component, Prop } from 'vue-property-decorator';
import { PendingDocumentReferenceUpdate } from '@/jbi-shared/types/document.types';

@Component({
  components: {}
})
export default class StagingTextSectionCitationDialog extends Vue {
  @Prop()
  public referenceNumber!: number;

  get modalTitle() {
    return `The selected citation is currently used by reference number ${this.referenceNumber}`;
  }

  public replace() {
    const mode: PendingDocumentReferenceUpdate['mode'] = 'replace';
    this.$emit('update:referenceCitation', mode);
    this.$emit('close');
  }

  public merge() {
    const mode: PendingDocumentReferenceUpdate['mode'] = 'merge';
    this.$emit('update:referenceCitation', mode);
    this.$emit('close');
  }
}
