

































































































import { Component, Prop } from 'vue-property-decorator';
import SectionTitleEditor from '@/components/editor/SectionEditor/SectionTitleEditor.vue';
import TagsEditor from '@/components/editor/SectionEditor/TagsEditor.vue';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import {
  PendingDocumentReferences,
  PendingSectionError,
  PendingSectionNotice,
  PendingTextSectionData,
  TagEntityTypeEnum
} from '@/jbi-shared/types/document.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import {
  EditorFocusElementType,
  EditorFocusStatus
} from '@/components/editor/Editor.vue';
import SectionError from '@/components/editor/SectionEditor/SectionError.vue';
import StagingTextSectionCitationToolbar from '@/views/StagingArea/components/SectionEditor/StagingTextSectionCitationToolbar.vue';
import StagingSectionValueEditor from './StagingSectionValueEditor.vue';
import { Citation } from '@/store/modules/documents/types/citations.types';
import { TextSectionDefaultTitle } from '../../../../jbi-shared/types/document.types';
import Op from 'quill-delta/dist/Op';

@Component({
  components: {
    SectionError,
    StagingSectionValueEditor,
    SectionTitleEditor,
    TagsEditor,
    StagingTextSectionCitationToolbar
  }
})
export default class StagingTextSectionEditor extends mixins(ViewModeMixin) {
  @Prop()
  public textSection!: PendingTextSectionData;
  @Prop()
  public focusStatus!: EditorFocusStatus | null;
  @Prop()
  public focusOn!: (id: number, type: EditorFocusElementType) => void;
  @Prop()
  public blurOn!: (id: number, type: EditorFocusElementType) => void;
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  @Prop()
  public dirtySectionsTagMaps!: DirtyTagMap[];
  @Prop()
  public dirtyReferences: PendingDocumentReferences | undefined;
  @Prop()
  public dirtyCitations!: Citation[];
  @Prop()
  public previousCitations!: Citation[];

  get EditorFocusElementType() {
    return EditorFocusElementType;
  }

  get TagEntityTypeEnum() {
    return TagEntityTypeEnum;
  }

  get hasErrorMessage(): boolean {
    return !!this.textSection?.errors || !!this.textSection?.notices;
  }

  get hasTitleError(): boolean {
    return Boolean(
      this.textSection?.notices?.find(
        (error) => error.code === PendingSectionNotice.SECTION_TITLE_NOT_MATCH
      )
    );
  }

  get hasValueError(): boolean {
    return Boolean(
      this.textSection?.errors?.find(
        (error) => error.code === PendingSectionError.SECTION_VALUE_EMPTY
      )
    );
  }

  public isFocused(id: number, type: EditorFocusElementType): boolean {
    return JSON.stringify({ id, type }) === JSON.stringify(this.focusStatus);
  }

  // Disable editing of Default Section Titles
  public isEditable(sectionTitle: Op[]): boolean {
    const title = sectionTitle[0].insert as String;
    const defaultTitles = (Object.values(
      TextSectionDefaultTitle
    ) as string[]).map((title) => title.toLowerCase());
    if (!defaultTitles.includes(title.toLocaleLowerCase())) {
      return false;
    }
    return true;
  }

  get entityId() {
    return this.textSection.content.tempId;
  }

  get isEntityIdTemp() {
    return Boolean(this.textSection.content.tempId);
  }
}
