


















































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { StagingSectionValueEditorMixin } from './StagingSectionValueEditor.mixin';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import StagingTextSectionCitationToolbar from './StagingTextSectionCitationToolbar.vue';

@Component({
  components: {
    StagingTextSectionCitationToolbar
  }
})
export default class StagingSectionValueEditor extends mixins(
  StagingSectionValueEditorMixin,
  ViewModeMixin
) {
  @Prop({ type: Boolean, default: false })
  public hasError!: boolean;
}
