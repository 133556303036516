
































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import {
  PendingDocumentCitationReference,
  PendingDocumentReferences,
  PendingDocumentReferenceUpdate
} from '@/jbi-shared/types/document.types';
import { get as _get, has as _has } from 'lodash';
import StagingTextSectionCitation from './StagingTextSectionCitation.vue';
import { Citation } from '@/store/modules/documents/types/citations.types';
import Op from 'quill-delta/dist/Op';

@Component({})
export default class StagingTextSectionCitationToolbar extends mixins(
  ViewModeMixin
) {
  @Prop()
  public readonly sectionValue!: Op[];
  @Prop()
  public dirtyCitations!: Citation[];
  @Prop()
  public previousCitations!: Citation[];
  @Prop()
  public dirtyReferences!: PendingDocumentReferences;

  get sectionCitationReference(): PendingDocumentCitationReference[] {
    const citationReference: PendingDocumentCitationReference[] = [];
    const citationReferenceSet = new Set();
    this.sectionValue.forEach((opText) => {
      if (_has(opText, 'insert.citation')) {
        const citationObject: PendingDocumentCitationReference = {
          citationId: _get(opText, 'insert.citation.citationId'),
          label: _get(opText, 'insert.citation.label')
        };
        const citationJson = JSON.stringify(citationObject);
        if (!citationReferenceSet.has(citationJson)) {
          citationReference.push(citationObject);
        }
        citationReferenceSet.add(citationJson);
      }
    });
    return citationReference;
  }

  public manageCitation(selected: PendingDocumentCitationReference) {
    this.$buefy.modal.open({
      parent: this,
      component: StagingTextSectionCitation,
      hasModalCard: true,
      fullScreen: true,
      canCancel: true,
      trapFocus: true,
      props: {
        dirtyReferences: this.dirtyReferences,
        dirtyCitations: this.dirtyCitations,
        previousCitations: this.previousCitations,
        currentCitation: selected
      },
      events: {
        updateReferenceCitation: (
          referenceUpdatePayload: PendingDocumentReferenceUpdate
        ) => {
          this.$emit('update:referenceCitation', referenceUpdatePayload);
        }
      }
    });
  }

  public deleteCitation(selected: PendingDocumentCitationReference) {
    this.$buefy.dialog.confirm({
      title: 'Delete This Reference',
      message:
        'You are going to delete the selected reference from this document. Continue?',
      confirmText: 'Delete',
      onConfirm: () => this.$emit('update:removeCitation', selected)
    });
  }
}
